@import '../../marketplace.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 36px;

  @media (--viewportMedium) {
    margin-top: 52px;
  }
}

.iconImage {
  margin-bottom: 7px;
}

.authenticateLink {
  color: var(--marketplaceColor);

  &:hover {
    cursor: pointer;
  }
}
