@import '../../marketplace.css';

.title {
  @apply --marketplaceH3FontStyles;
  margin-top: 150px;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 36px;

  @media (--viewportMedium) {
    margin-top: 52px;
  }
}

.iconImage {
  margin-bottom: 7px;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 0;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.error {
  color: var(--failColor);
}

.authenticationData {
  display: flex;
  flex-direction: column;
}

.authenticationSectionTitle {
  @apply --marketplaceH2FontStyles;
  margin-bottom: 40px;
}

.infoLine {
  margin-bottom: 20px;
}

.subscribeButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 50px;
  }
}

.unsubscribeButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 50px;
  }
}

.disclaimerText {
  font-size: 14px;
  color: #4A4A4A;
  margin-top: 25px;
}
