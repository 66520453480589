@import '../../marketplace.css';

select {
  background-image: none!important;
}

.root {
}

.tabs {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    height: 55px;
    align-items: flex-end;
    padding: 13px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.couponPanel {
  width: calc( 100% - 48px );
  margin: 24px 24px 0 24px;
  font-size: 16px;

  @media (--viewportMedium) {
    margin: 48px 24px 0 24px;
  }

  @media (--viewportLarge) {
    margin: 80px 24px 0 24px;
  }
}

.panelHeading {
  display: flex;
  flex-direction: row;
  font-size: 20px;
}

.editCouponLinkBlock {
  margin-left: auto;
  text-align: right;
}

.link {
  white-space: nowrap;
  font-size: 18px;
}

.title {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 24px 48px 24px;
  }

  @media (--viewportLarge) {
    margin: 0 36px 48px 36px;
  }
}

.dataTableWrapper {
  width: 100%;
  font-size: 18px;
}

.editCell {
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.deleteCell {
  color: var(--marketplaceColor);
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}
